import Vue from 'vue'

const vm = new Vue()
// 注册获取短信验证码
export function smsCode(mobile) {
  return vm.$get(
    `/api/scrm/common/register/sms-code?mobile=${mobile}`,
  )
}

export function register(params) {
  return vm.$post(
    '/api/scrm/common/register/register-by-mobile',
    params,
  )
}
