<template>
  <div class="register-panel">
    <p class="title">
      {{platformName}}用户注册
    </p>
    <a-form :form="registerForm" @submit="register" class="register-form">
      <a-form-item>
        <a-input
          type="text"
          v-decorator="['userName', { rules: [{ required: true, message: '请输入姓名' }] }]"
          placeholder="请输入姓名"
          auto-complete="off"
          @pressEnter="register"
        ></a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          type="text"
          v-decorator="['mobile', { rules: [{ validator: mobileValid }] }]"
          placeholder="请输入手机号"
          auto-complete="off"
          :maxLength="11"
          @pressEnter="register"
        ></a-input>
      </a-form-item>
      <a-form-item style="margin-bottom: 0;" class="inline-form-item sms-code-form-item">
        <a-form-item class="smsCode-input-row">
          <a-input
            type="text"
            v-decorator="['smsCode', { rules: [{ required: true, message: '请输入验证码' }] }]"
            placeholder="请输入验证码"
            auto-complete="off"
            @pressEnter="register"
          ></a-input>
        </a-form-item>
        <a-form-item :style="{ display: 'inline-block', minWidth: '110px' }">
          <a-button
            :loading="smsLoading"
            type="primary"
            class="send-sms-btn"
            :class="[{'disabled': count > 0 }]"
            :disabled="count > 0"
            @click="getCode">{{ count > 0 ? `${count}s后重新获取` : (smsId ? '重新发送' : '获取验证码') }}</a-button
          >
        </a-form-item>
      </a-form-item>
      <a-form-item>
        <a-button
          :loading="registerLoading"
          class="submit-button"
          html-type="submit"
          >立即注册</a-button
        >
      </a-form-item>
      <div class="go-to-register">
        <span @click="$emit('loginChange')">已有账号？立即登录</span>
      </div>
    </a-form>
  </div>
</template>

<script>
import { smsCode, register } from '@/api/register'

export default {
  props: {
    platformName: {
      type: String,
    },
  },
  data() {
    return {
      registerForm: this.$form.createForm(this, { name: 'register' }),
      registerLoading: false,
      count: 0,
      timer: null,
      smsId: null,
      smsLoading: false,
    }
  },
  watch: {
    count(val) {
      if (val) {
        this.timer = setTimeout(this.countDown, 1000)
      }
    },
  },
  methods: {
    countDown() {
      this.count -= 1
    },
    mobileValid(rule, value, callback) {
      if (!value) {
        callback('请输入手机号码')
      } else if (!/^1\d{10}$/.test(value)) {
        callback('手机号码格式错误')
      } else {
        callback()
      }
    },
    register(e) {
      e && e.preventDefault()
      this.registerForm.validateFields(async (err, values) => {
        if (!err) {
          this.registerLoading = true
          try {
            const params = {
              ...values,
              smsId: this.smsId,
            }
            const data = await register(params)
            if (data.code === 200) {
              this.$notification.success({ message: data.msg || '注册成功' })
              this.$emit('registerSuccess')
            }
            this.registerLoading = false
          } catch (error) {
            this.registerLoading = false
          }
        }
      })
    },
    async getCode() {
      if (this.count > 0) return
      const mobile = this.registerForm.getFieldValue('mobile')
      if (!mobile || !/^1\d{10}$/.test(mobile)) {
        this.registerForm.validateFields(['mobile'])
        return
      }
      this.smsLoading = true
      try {
        const { data } = await smsCode(mobile)
        this.count = 60
        this.smsId = data
        this.smsLoading = false
      } catch (error) {
        console.log(error)
        this.smsLoading = false
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
}
</script>
<style lang="scss" scoped>
.register-panel {
  width: 450px;
  padding: 55px 35px;
  margin-left: 100px;
  background-color: $white;
  border-radius: 11px;
  box-shadow: 0 6px 22px 0 rgba(51, 62, 153, 0.7);
}

.title {
  font-size: 24px;
  color: $lighter-black;
  text-align: center;
  margin: 0 0 24px 0;
  font-weight: bold;
}

.register-form {
  /deep/ .ant-input {
    height: 56px;
    border: 1px solid $lighter-gray;
    background: $white;
    border-radius: 4px;
  }
}
.submit-button {
  width: 100%;
  border: none;
  height: 56px;
  background: $bg-blue;
  border-radius: 4px;
  color: $white;
  font-size: 20px;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
}
.send-sms-btn {
  width: 100%;
  font-size: 14px;
  padding: 13px 0;
  height: 56px;
  margin-left: 10px;
}
.sms-code-form-item {
  /deep/ .ant-form-item-children {
    display: flex;
    .smsCode-input-row {
      flex: 1;
    }
  }
}
.go-to-register {
  display:flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: bold;
  span {
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>
