<template>
  <div class="login-container" :style="`background-image:url(${bgUrl})`">
    <div class="client-bg" />
    <div class="login-panel" v-if="!showRegister">
      <p class="title" @click="turnOnVconsole">
        欢迎登录{{ oem.platformName || currentPlatName }}
      </p>
      <a-form :form="loginForm" @submit="login" class="login-form">
        <a-form-item>
          <a-input
            type="text"
            v-decorator="username"
            placeholder="请输入登录账号"
            auto-complete="off"
            @pressEnter="login"
            @change="trimName($event, 'username')"
            @blur="trimName($event, 'username')"
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-input-password
            v-decorator="password"
            placeholder="请输入密码"
            auto-complete="off"
            @pressEnter="login"
            @change="trimName($event, 'password')"
            @blur="trimName($event, 'password')"
          ></a-input-password>
        </a-form-item>
        <a-form-item>
          <a-button
            :loading="loginLoading"
            class="submit-button"
            html-type="submit"
            >登录</a-button
          >
        </a-form-item>
        <div class="go-to-register" v-if="isScrm">
          <span @click="showRegister = true">还没有账号？立即注册</span>
        </div>
      </a-form>
    </div>

    <ScrmRegister
      @loginChange="showRegister = false"
      @registerSuccess="registerSuccess"
      :platformName="oem.platformName || currentPlatName"
      v-if="showRegister"
    />

    <template v-if="icp !== '0'">
      <p v-if="isAYG" class="icp" :style="[isSales ? colorStyle : {}]">
        Copyright 2017 - 2022
        <span @click="toBeiAn" :style="[pointerStyle]">{{
          icp || "粤ICP备14088498号-1"
        }}</span>
      </p>
      <p v-else class="icp">
        Copyright 2017 - 2022
        <span @click="toBeiAn" :style="[pointerStyle]">{{
          icp || "粤ICP备14088498号-7"
        }}</span>
      </p>
    </template>
    <confirm-model
      :confirmModal="confirmModal"
      :arr="urlList"
      @handleClose="handleClose"
    />
    <switch-profile-modal
      ref="profileModal"
      :visible.sync="showSwitchModal"
      :change="false"
    />
    <ScrmSwitchUserLoginDialog
      ref="scrmSwitchUserLoginDialog"
      :visible.sync="showScrmDialog"
      :list="scrmUserList"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import VConsole from 'vconsole'
import { login } from '@/api/login'
import platformEnum from '@/utils/platformEnum'
import defaultBgUrl from '@/assets/open.jpg'
import confirmModel from './components/confirmModel.vue'
import switchProfileModal from '@/components/switchProfile/index.vue'
import ScrmRegister from './components/register.vue'
import ScrmSwitchUserLoginDialog from '@/components/ScrmSwitchUserLogin/index.vue'
import { getSelectedChannel } from '@/utils/auth'
import Cookies from 'js-cookie'

const showSwitchModalPlatforms = ['console-agent', 'console-provider', 'console-scrm']

export default {
  components: {
    confirmModel,
    switchProfileModal,
    ScrmRegister,
    ScrmSwitchUserLoginDialog,
  },
  data() {
    return {
      pointerStyle: {
        cursor: 'pointer',
      },
      colorStyle: {
        color: '#A7B2C6',
      },
      loginForm: this.$form.createForm(this, { name: 'login' }),
      username: [
        'username',
        {
          rules: [
            {
              required: true,
              message: '请输入登录账号',
            },
          ],
        },
      ],
      password: [
        'password',
        {
          rules: [
            {
              required: true,
              message: '请输入密码',
            },
          ],
        },
      ],
      remember: [
        'remember',
        {
          valuePropName: 'checked',
          initialValue: false,
        },
      ],
      defaultBgUrl,
      bgUrl: '',
      currentPlat: '', // 当前子项目，根据域名判断
      currentPlatName: '', // 当前子项目明
      isAYG: window.location.host.includes('aiyuangong.com'), // 当前域名是否为aiyuangong.com
      isSales: window.location.host.includes('sales'), // 判断是否为渠道商平台（它的背景是白色的，备案号透明度另设）
      clickTimes: 0, // 点击欢迎登录次数
      vconsole: null,
      urlList: [],
      confirmModal: false,
      showSwitchModal: false,
      showRegister: false,
      isScrm: window.location.host.includes('scrm'), // 判断是否为善赚营销服务平台（显示注册入口）
      showScrmDialog: false,
      scrmUserList: [],
    }
  },
  computed: {
    ...mapState(['oem', 'loginLoading', 'platformType']),
    icp() {
      if (this.oem.icp === '0') return '0'
      return this.oem.icp && this.oem.icp.includes('ICP备') ? this.oem.icp : ''
    },
  },
  created() {
    // 进入登录页时，把isLogin删掉
    localStorage.removeItem('isLogin')
    // 这里需要分域名来加载不同的背景图和登录词，目前只需要区分oem和非OEM
    const currentPlat = Object.keys(platformEnum).filter((item) => window.location.host.includes(platformEnum[item].host))
    if (currentPlat.length) {
      [this.currentPlat] = currentPlat
      this.currentPlatName = platformEnum[this.currentPlat].logoName
      this.defaultBgUrl = platformEnum[this.currentPlat].loginBackground // 登录页背景图
    }
    this.loadImg((res) => {
      this.bgUrl = res
    })
  },
  mounted() {
    const account = window.localStorage.getItem('account')
    if (account) {
      const { name, password } = JSON.parse(account)
      this.loginForm.setFieldsValue({
        username: name,
        password,
      })
    }
  },
  methods: {
    ...mapMutations(['updateState', 'LOGIN_ACTION']),
    ...mapActions(['getMenu']),
    trimName({ target: { value } }, name) {
      const trimValue = value.replace(/(^\s*)|(\s*$)/g, '')
      this.loginForm.setFieldsValue({
        [name]: trimValue,
      })
    },
    turnOnVconsole() {
      this.clickTimes += 1
      if (this.clickTimes > 3 && !this.vconsole) {
        console.log('vconsole on')
        this.vconsole = new VConsole({})
        // 设置rr-block，vconsole元素不被rrweb监听
        this.vconsole.$dom.setAttribute('class', 'rr-block')
        // console.log(this.vconsole.$dom)
      }
    },
    handleClose() {
      this.confirmModal = false
    },
    login(e) {
      e && e.preventDefault()
      const that = this
      this.updateState({ name: 'loginLoading', value: true })
      that.loginForm.validateFields(async (err, values) => {
        if (!err) {
          const { username, password } = values
          try {
            const { data } = await login(username, password)
            const formmerUserId = localStorage.getItem('userId')
            if (formmerUserId - 0 !== data.userId - 0) {
              // 切换账号把currentChosen、userContext清空
              localStorage.removeItem('currentChosen')
              localStorage.removeItem('userContext')
              localStorage.removeItem('headerConfig')
              localStorage.removeItem('userProfiles')
              Cookies.remove('x-sec-profile')
              Cookies.remove('redash-profile')
              Cookies.remove('x-sec-employee')
            }
            await that.LOGIN_ACTION(data)
            const { userProfiles, platformType } = data
            if (showSwitchModalPlatforms.includes(platformType)) {
              that.handleDefaultSubjectChoose(userProfiles)
            } else {
              // 获取菜单
              that.getMenu()
            }
          } catch (error) {
            this.updateState({ name: 'loginLoading', value: false })
            const { code, message, msg } = error
            const errorEnum = {
              498: () => {
                // saas权限改造，状态为498时，跳转不用平台去登录
                that.confirmModal = true
                that.urlList = error.data
              },
              499: () => {
                // 该账户拥有租户的情况下，选择租户然后登录
                that.$notification.error({ message: msg || message })
              },
            }
            const func = errorEnum[code] || (() => {})
            func()
          }
        } else {
          this.updateState({ name: 'loginLoading', value: false })
        }
      })
    },
    rememberAccount(account) {
      window.localStorage.setItem('account', JSON.stringify(account))
    },
    async handleDefaultSubjectChoose(userProfiles = []) {
      const { platformType } = this
      /* 善赚营销平台 subjectId为空未填写入驻资料 */
      if (platformType === 'console-scrm') {
        this.toScrmRegister(userProfiles)
        return
      }
      const selectedEnum = {
        'console-agent': getSelectedChannel,
        'console-provider': getSelectedChannel,
      }

      const formerSelected = Number(selectedEnum[platformType].call())
      const [profile] = userProfiles
      const subject = userProfiles.find((el) => el.id === formerSelected) || profile
      const isFirstLogin = !formerSelected || !userProfiles.find((el) => el.id === formerSelected)
      if (isFirstLogin) {
        // 首次或切换不同权限账号登陆了，清掉原来选择的东西
        Cookies.remove('redash-profile')
        Cookies.remove('x-sec-profile')
        Cookies.remove('x-sec-employee')
        Cookies.remove('x-sec-subject-company-id')
        Cookies.remove('x-sec-subject-company-name')
        Cookies.remove('x-sec-subject-app-id')
        Cookies.remove('x-sec-subject-app-name')
        Cookies.remove('x-sec-subject-provider-id')
        Cookies.remove('x-sec-subject-provider-name')
        await localStorage.removeItem('currentChosen')
        await localStorage.removeItem('headerConfig')
        await localStorage.removeItem('selectedChannel')
        await localStorage.removeItem('selectedAgent')
        await localStorage.removeItem('userProfiles')
      }

      this.showSwitchModal = isFirstLogin && userProfiles.length > 1
      !this.showSwitchModal && subject && this.$refs.profileModal.handleSubjectChange(subject.id)
    },
    // 获取背景图片
    loadImg(callback) {
      const url = `/api/sysmgr-web/file/oem-agent-scan?targetType=oem_home&targetExt=${window.location.host}&zoomImage=false`
      const img = new Image()
      const that = this
      img.src = url
      img.onload = () => {
        if (img.width !== 0) {
          callback(url)
        } else {
          callback(that.defaultBgUrl)
        }
      }
      img.onerror = () => {
        callback(that.defaultBgUrl)
      }
    },
    // 跳转至备案中心
    toBeiAn() {
      window.open('https://beian.miit.gov.cn/')
    },
    registerSuccess() {
      this.showRegister = false
    },
    toScrmRegister(userProfiles) {
      const { platformType } = this
      const subjectIdEmpty = userProfiles.filter((item) => item.subjectId)
      window.localStorage.removeItem('isScrmRegister')
      if (subjectIdEmpty.length === 0) {
        this.updateState({ name: 'loginLoading', value: false })
        /* 清掉channels 登录返回的userProfiles 为入驻 userProfiles不完整 */
        window.localStorage.removeItem('channels')
        const { origin } = window.location
        const Path = '/micro-scrm/agent-register'
        window.location.href = `${origin}${Path}`
      } else {
        const selectedEnum = {
          'console-scrm': getSelectedChannel,
        }
        const formerSelected = Number(selectedEnum[platformType].call())
        const [profile] = userProfiles
        const subject = userProfiles.find((el) => el.id === formerSelected) || profile
        this.scrmUserList = userProfiles
        this.$nextTick(() => {
          this.showScrmDialog = userProfiles.length > 1
          !this.showScrmDialog && subject && this.$refs.scrmSwitchUserLoginDialog.scrmAgentLogin(subject)
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/style/login.scss";
.go-to-register {
  display:flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: bold;
  span {
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>
